<div class="container">
  <div class="row">
    <div class="col-12 px-0">
      <div class="d-flex justify-content-between align-items-center mt-4 mb-4">
        <a class="page-title">Credits Sharing</a>
        <div class="btn-wrapper">
          <button
            type="button" class="btn btn-primary main-user-button"
            [disabled]="loading || loadingUserAction"
            (click)="openUserSettingsModal()">
            Add User
          </button>
        </div>
      </div>
      <table class="table table-hover" *ngIf="!loading else loadingTemplate">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">First Name</th>
            <th scope="col">Last Name</th>
            <th scope="col">Email</th>
            <th scope="col" class="text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let teamUser of teamUsers; index as i">
            <th scope="row">{{ i + 1 }}</th>
            <td>
              {{ teamUser.firstName }}
            </td>
            <td>{{ teamUser.lastName }}</td>
            <td>{{ teamUser.email }}</td>
            <td class="text-center">
              <button type="button" class="btn user-icon" [disabled]="teamUser.id === currentUser.id" (click)="openDeleteUserConfirmationModal(teamUser)">
                <img src="../../assets/trash.png">
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<ng-template #userSettingsModal let-c="close" let-d="dismiss">
  <div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Add user</h4>
	</div>
	<div class="modal-body pt-3">
    <form [formGroup]="userGroup">

		<div class="mb-4 relative">
      <label class="mb-0">First name</label>
			<input type="text" formControlName="firstName" placeholder="First Name" class="form-control"/>
      <p class="error-txt" *ngIf="userGroup.controls.firstName.touched && userGroup.controls.firstName.hasError('required')">First name can not be empty</p>
		</div>

    <div class="mb-4 relative">
      <label class="mb-0">Last name</label>
			<input type="text" formControlName="lastName"  placeholder="Last Name" class="form-control"/>
      <p class="error-txt" *ngIf="userGroup.controls.lastName.touched && userGroup.controls.lastName.hasError('required')">Last name can not be empty</p>
		</div>

    <div class="mb-4 relative">
      <label class="mb-0">Email</label>
			<input type="email" formControlName="emailAdress" placeholder="Email" class="form-control"/>
      <p class="error-txt" *ngIf="userGroup.controls.emailAdress.touched && userGroup.controls.emailAdress.hasError('required')">Email name can not be empty</p>
      <p class="error-txt" *ngIf="userGroup.controls.emailAdress.touched && userGroup.controls.emailAdress.hasError('email')">Not an email</p>
      <p class="error-txt" *ngIf="userGroup.controls.emailAdress.touched && userGroup.controls.emailAdress.hasError('emailAlreadyInUse')">User is already in this team.</p>
      <p class="error-txt" *ngIf="userGroup.controls.emailAdress.touched && addingUserError">User with this email address doesn't exist.</p>
		</div>
  </form>
	</div>
	<div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-secondary" [disabled]="loadingUserAction" (click)="close()">Cancel</button>
		<button type="button" class="btn btn-primary user-button" [disabled]="!canSave || loadingUserAction" (click)="save()">Save</button>
	</div>
</ng-template>

<ng-template #deleteUserConfirmationModal let-c="close" let-d="dismiss">
  <div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Confirm Delete</h4>
	</div>
	<div class="modal-body py-4">
		<p *ngIf="userToBeDeleted" class="mb-0">Are you sure you want to delete user {{ userToBeDeleted.firstName }} {{ userToBeDeleted.lastName }}?</p>
	</div>
	<div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-secondary" [disabled]="loadingUserAction" (click)="close()">Cancel</button>
		<button type="button" class="btn btn-danger" [disabled]="loadingUserAction" (click)="close(true)">Delete</button>
	</div>
</ng-template>

<ng-template #loadingTemplate>
  <div class="loader">
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
  </div>
</ng-template>
